@media (max-width: 992px) {
  .bg-banner {
    margin-top: 70px;
  }
  .bg-home {
    margin-top: 70px;
    /* background-image: url(../src/assets/images/home-banner-11.jpg) !important; */
  }
}

.bg-home {
  background-position: center !important;
}

.bg-banner img {
  width: 100%;
  height: auto;
}

.social-icon li a {
  height: 48px;
  width: 48px;
}

figure.fig-gal {
  height: 180px;
  overflow: hidden;
}

figure.fig-gal img {
  object-fit: cover;
}
